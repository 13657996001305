<template>
  <div class="clientTag">
    <el-tabs type="border-card"  v-model="tagName">
      <el-tab-pane v-for="(tagForm, index) in tagFormConfig" :key="'tagForm' + index" :label="tagForm.label">
        <com-form
          :ref="tagForm.value"
          label-width="160px"
          label-position="right"
          :col="3"
          :configs="tagForm.tagConfigs"
          showType="info"
        ></com-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { formConfig } from "../config/formConfig.js"
export default {
  name: 'tag',
  data() {
    const config = formConfig(this);
    return {
      tagName: '',
      formData: {},
      tagFormConfig: config.tagFormConfig
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>