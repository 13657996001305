export const tab_en = {
  "Thead": "THead",
  "mqs_move_task_tab1": "mqs_move_task_tab1",
  "mqs_move_task_tab0": "mqs_move_task_tab0",
  "mqs_inventory_tab2": "mqs_inventory_tab2",
  "mqs_inventory_tab30": "mqs_inventory_tab30",
};

export const tab_zh = {
  "Thead": "表头",
  "mqs_move_task_tab1": "杂项",
  "mqs_move_task_tab0": "综合",
  "mqs_inventory_tab2": "库存属性",
  "mqs_inventory_tab30": "用户自定义的_设置1",
}